import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import { History } from "history"
import { reducer as toastrReducer } from "react-redux-toastr"
import { persistReducer, createMigrate } from "redux-persist"
import storage from "redux-persist/lib/storage"

import { runQCReducer } from "../data/stores/run-qc/run-qc-api-reducer"
import { sampleReducer } from "../data/stores/sample-setup/sample-setup-api-reducer"
import { coreReducer } from "./store/core-reducers"
import { authReducer } from "../core-components/login/store/login-reducers"
import { runDesignReducer } from "../data/stores/run-design/run-design-api-reducer"
import { instrumentReducer } from "../data/stores/instrument/instrument-reducer"
import { usersReducer } from "../data/stores/users/users-api-reducer"
import { datasetSelectorReducer } from "../data/stores/dataset-selector/dataset-selector-api-reducer"
import { analysisResultsReducer } from "../data/stores/analysis/analysis-results/analysis-results-reducer"
import { partsReducer } from "../data/stores/parts/parts-api-reducer"
import { filenamePrefixReducer } from "../data/stores/filename-prefix/filename-prefix-reducer"
import { analysisPollingReducer } from "../data/stores/analysis/analysis-polling/analysis-polling-reducer"
import { rulesReducer } from "../data/stores/rules/rules-reducer"
import { multiJobPollingReducer } from "../data/stores/analysis/multijob-polling/multijob-polling-reducer"
import { datasetDetailsReducer } from "../data/stores/dataset-details/dataset-details-reducer"
import { runSelectorReducer } from "../data/stores/run-selector/run-selector-reducer"
import { projectReducer } from "../data/stores/project/project-api-reducer"
import { alarmReducer } from "../data/stores/alarm/alarm-api-reducer"
import { aboutReducer } from "../data/stores/about/about-api-reducer"
import { analysisSelectorReducer } from "../data/stores/analysis/analysis-selector/analysis-selector-reducer"
import { newAnalysisReducer } from "../data/stores/analysis/new-analysis/new-analysis-reducer"
import { devReducer } from "../data/stores/dev/dev-reducer"
import { barcodeSampleNamesReducer } from "../data/stores/barcode-sample-names/barcode-sample-names-reducer"
import { InstrumentSupport } from "../data/stores/instrument/instrument-types"
import { notificationsReducer } from "../data/stores/notifications/notifications-reducer"
import { instrumentTableReducer } from "../data/stores/instrument-table.ts/instrument-table-reducer"
import { instrumentSettingsReducer } from "../data/stores/instrument-settings/instrument-settings-reducer"
import { entryPointsReducer } from "../data/stores/entry-points.ts/entry-points-reducer"
import { userPreferencesReducer } from "../data/stores/user-preferences/user-preferences-reducer"
import { InstrumentType, InstrumentTypeToCorrectedTypeMap, isSequel } from "../data/model/instrument-type-model"
import { templateFilesReducer } from "../data/stores/template-files/template-files-reducer"
import { tenantReducer } from "src/data/stores/tenant/tenant-reducer"
import { analysisOptionsReducer } from "src/data/stores/analysis-options/analysis-options-reducer"

const instrumentMigrations = {
    0: (state) => {
        const updatedInstrumentSupport: InstrumentSupport = Object.assign({}, state.instrumentSupport, {sequelIIe: true})
        const isUnsupported = (!state.currentInstrumentType || isSequel(state.currentInstrumentType))
        const updatedCurrentInstrumentType: InstrumentType = isUnsupported ? "Revio" : state.currentInstrumentType
         // This check should live in the most recent "version"
        return {
            ...state,
            isSequel: undefined,
            instrumentSupport: updatedInstrumentSupport,
            currentInstrumentType: updatedCurrentInstrumentType
        }
    },
    1: (state) => {
        const updatedInstrumentSupport: InstrumentSupport = Object.assign({}, state.instrumentSupport, {revio: true})
        const isUnsupported = (!state.currentInstrumentType || isSequel(state.currentInstrumentType))
        const updatedCurrentInstrumentType: InstrumentType = isUnsupported ? "Revio" : state.currentInstrumentType
        return {
            ...state,
            instrumentSupport: updatedInstrumentSupport,
            currentInstrumentType: updatedCurrentInstrumentType
        }
    },
    2: (state) => {
        const updatedCurrentInstrumentType: InstrumentType =
            InstrumentTypeToCorrectedTypeMap[state.currentInstrumentType] as InstrumentType
        const updatedInstrumentSupport = {
            supportsSequel: state.instrumentSupport.sequel,
            supportsSequel2: state.instrumentSupport.sequelII,
            supportsSequel2e: state.instrumentSupport.sequelIIe,
            supportsRevio: state.instrumentSupport.revio
        }

        return {
            ...state,
            instrumentSupport: updatedInstrumentSupport,
            currentInstrumentType: updatedCurrentInstrumentType
        }
    }
}

const instrumentStateReconciler = (inboundState, originalState) => {
    const isValid =
        "supportsSequel" in inboundState.instrumentSupport &&
        "supportsSequel2" in inboundState.instrumentSupport &&
        "supportsSequel2e" in inboundState.instrumentSupport &&
        "supportsRevio" in inboundState.instrumentSupport
    if (!isValid) {
        return originalState
    } else {
        return inboundState
    }
}

export const createRootReducer = (history: History) => {
    const rootPersistConfig = {
        key: "root",
        storage,
        whitelist: ["auth"]
    }

    const authPersistConfig = {
        key: "auth",
        storage,
        blacklist: ["loading"]
    }

    const instrumentPersistConfig = {
        key: "instrument",
        version: 2,
        storage,
        migrate: createMigrate(instrumentMigrations, { debug: false }),
        stateReconciler: instrumentStateReconciler
    }

    const prefixPersistConfig = {
        key: "prefix",
        storage,
        blacklist: ["loading"]
    }

    const projectPersistConfig = {
        key: "project",
        storage,
        blacklist: ["loading"]
    }

    const aboutPersistConfig = {
        key: "about",
        storage,
        blacklist: [
            "chemistryBundleCurrentVersionLoading:", "chemistryBundleCurrentVersionError",
            "chemistryBundleUpdateAvailableLoading", "chemistryBundleUpdateAvailableError",
            "chemistryBundleUpgradeLoading", "chemistryBundleUpgradeError",
            "uiBundleBundleCurrentVersionLoading", "uiBundleBundleCurrentVersionError",
            "uiBundleBundleUpdateAvailableLoading", "uiBundleBundleUpdateAvailableError",
            "uiBundleBundleUpgradeLoading", "uiBundleBundleUpgradeError"
        ]
    }

    const devPersistConfig = {
        key: "dev",
        storage,
        blacklist: ["loading"]
    }

    const rootReducer = combineReducers({
        alarm: alarmReducer,
        core: coreReducer,
        auth: persistReducer(authPersistConfig, authReducer),
        userPreferences: userPreferencesReducer,
        runQC: runQCReducer,
        instrumentTable: instrumentTableReducer,
        sampleSetup: sampleReducer,
        runDesign: runDesignReducer,
        analysisOptions: analysisOptionsReducer,
        instrument: persistReducer(instrumentPersistConfig, instrumentReducer),
        users: usersReducer,
        datasetSelector: datasetSelectorReducer,
        project: persistReducer(projectPersistConfig,projectReducer),
        datasetDetails: datasetDetailsReducer,
        analysisResults: analysisResultsReducer,
        analysisPoller: analysisPollingReducer,
        multiJobsPoller: multiJobPollingReducer,
        analysisSelector: analysisSelectorReducer,
        newAnalysis: newAnalysisReducer,
        runSelector: runSelectorReducer,
        rules: rulesReducer,
        toastr: toastrReducer,
        router: connectRouter(history),
        parts: partsReducer,
        entryPoints: entryPointsReducer,
        prefix: persistReducer(prefixPersistConfig,filenamePrefixReducer),
        barcodeSampleNames: barcodeSampleNamesReducer,
        about: persistReducer(aboutPersistConfig, aboutReducer),
        notifications: notificationsReducer,
        instrumentSettings: instrumentSettingsReducer,
        dev: persistReducer(devPersistConfig, devReducer),
        templateFiles: (templateFilesReducer),
        tenant: tenantReducer
    })

    return persistReducer(rootPersistConfig, rootReducer)
}
