import { PipelineTemplate } from "src/data/model/pipeline-template-models"
import { EntryPointSelections } from "src/silos/analysis/utils/pipeline-settings-job-creator"
import _ from "lodash"

export interface SampleAnalysisState {
  selectedPipeline: PipelineTemplate,
  entryPointSelections?: EntryPointSelections
}
export const AnalysisOptionsActionTypes = {
  SET_IS_RUN_LOADED: "@@analysisOptions/SET_IS_RUN_LOADED",
  SET_IS_SAVING_RUN: "@@analysisOptions/SET_IS_SAVING_RUN",
  SET_SAMPLE_STATES: "@@analysisOptions/SET_SAMPLE_STATES",
  SET_SAMPLE_STATE:  "@@analysisOptions/SET_SAMPLE_STATE",
  INSERT_SAMPLE_STATE:  "@@analysisOptions/INSERT_SAMPLE_STATE",
  DELETE_SAMPLE_STATE:  "@@analysisOptions/DELETE_SAMPLE_STATE",
  SET_SAMPLE_EPS: "@@analysisOptions/SET_SAMPLE_EPS",
  SET_SAMPLE_PIPELINE: "@@analysisOptions/SET_SAMPLE_PIPELINE",
  CLEAR_ALL: "@@analysisOptions/CLEAR_ALL",
  
}

export interface AnalysisOptionsState {
  readonly isRunLoaded: boolean
  readonly isSavingRun: boolean
  readonly sampleStates: SampleAnalysisState[]
}

export const hasAnalysis = (sampleState: SampleAnalysisState):boolean => {
  return !_.isEmpty(sampleState) && !_.isEmpty(sampleState.selectedPipeline)
}