import { Model } from "./model"
import { AnalysisRequest, DeferredJob } from "./job-options-model"
import { XMLElement } from "../utils/shared/xml/xml"
import { appConfig } from "../../core/config/app-config"
import { GENERAL_PROJECT_ID } from "../services/project-service"
import { CollectionStateValue, RunStateValue } from "./run-qc-model"
import { ChipType, InstrumentType } from "./instrument-type-model"
import moment from "moment"
import { BarcodedSample } from "./run-design-model"

export interface DesignObject {
    uniqueId?: string
    name?: string
    summary?: string
    status?: RunStateValue
    dateCreated?: string
    createdAt?: string
    reserved?: boolean
    dataModel?: string
    totalCells?: number
    numStandardCells?: number
    numLRCells?: number
    run?: RunObject
    multiJobId?: number
    chipType?: ChipType
    instrumentType?: InstrumentType
}

export class DesignModel extends Model implements DesignObject {
    uniqueId = ""
    name = ""
    summary = ""
    dateCreated = ""
    status = null
    createdAt = ""
    reserved = false
    totalCells = 0
    numStandardCells = 0
    numLRCells = 0
    multiJobId = 0
    chipType = "1mChip" as ChipType
    instrumentType = "sequel" as InstrumentType
    run: RunModel

    constructor(object?: DesignObject) {
        super()

        this.initialize(object)

        this.run = new RunModel(this.run)
    }

    update(object: DesignObject) {
        Object.keys(object).forEach(key => {
            if (key === "run") {
                const runValue = object[key]
                if (runValue) {
                    this.run.update(runValue)
                }
            } else {
                this[key] = object[key]
            }
        })

        if (object.run) {
            if (!object.name) {
                this.name = this.run.runName
            }
            if (!object.summary) {
                // eslint-disable-next-line
                const plural = (this.run.samples.length !== 1) ? "s" : ""
                this.summary = this.run.runDescription
            }
        }
    }

    get runSummary() {
        // similar to runSummary in run-qc-model.ts
        let cellsRequiredStandard = this.numStandardCells
        let cellsRequiredLR = this.numLRCells
        if (!cellsRequiredStandard && !cellsRequiredLR) {
            cellsRequiredStandard = this.totalCells
        }
        if (this.chipType === "8mChip") {
            cellsRequiredStandard = this.totalCells
            cellsRequiredLR = 0
        }

        const pluralStandard = (cellsRequiredStandard !== 1) ? "s" : ""
        const cellsRequiredStandardPrefix = cellsRequiredStandard ? `${cellsRequiredStandard} SMRT Cell${pluralStandard}` : ""

        const pluralLR = (cellsRequiredLR !== 1) ? "s" : ""
        const cellsRequiredLRPrefix = cellsRequiredLR ? `${cellsRequiredLR} SMRT Cell${pluralLR} LR` : ""

        let summaryPrefix = cellsRequiredStandardPrefix
        if (cellsRequiredLRPrefix) {
            if (summaryPrefix) { summaryPrefix += ", " }
            summaryPrefix += cellsRequiredLRPrefix
        }

        return (!this.summary || !this.summary.startsWith(summaryPrefix))
            ? summaryPrefix + (this.summary ? ", " + this.summary : "")
            : this.summary
    }
}

export class RunModel extends Model implements RunObject {
    createdBy = ""
    experimentId = ""
    experimentName = ""
    plate1Barcode = ""
    plate2Barcode = ""
    assignedWells1 = []
    assignedWells2 = []
    plate1Label = ""
    plate2Label = ""
    experimentDescription = ""
    runName = "Run " + moment().format("MM.DD.YYYY HH:mm")
    runDescription = ""
    uuid = ""
    chipType = "1mChip" as ChipType
    instrumentType = "sequel" as InstrumentType
    samples: SampleModel[] = [
        new SampleModel()
    ]
    multiJobId = 0

    constructor(object?: RunObject) {
        super()

        this.initialize(object)

        if (object) {
            if (object.samples) {
                this.samples = object.samples.map((sample) => {
                    return new SampleModel(sample)
                })
            }
        }
    }

    update(object: RunObject) {
        Object.keys(object).forEach(key => {
            if (key !== "samples") {
                this[key] = object[key]
            } else if (object.samples) {
                object.samples.forEach((sample, index) => {
                    this.samples[index].update(sample)
                })
            }
        })
    }
}

export interface RunObject {
    experimentId?: string
    experimentName?: string
    experimentDescription?: string
    runName?: string
    plate1Barcode?: string
    plate2Barcode?: string
    assignedWells1?: string[]
    assignedWells2?: string[]
    runDescription?: string
    uuid?: string
    chipType?: ChipType
    instrumentType?: InstrumentType
    samples?: SampleObject[]
    multiJobId?: number
}

export interface SampleObject extends AnalysisRequest{
    sampleName?: string
    biosampleName?: string
    collectionsPerSMRTCell?: number
    wellName?: string
    plateNumber?: number
    application?: string
    libraryType?: string
    cellName?: string
    sampleDescription?: string
    insertSize?: number
    projectId?: number
    loadingConcentration?: number
    sampleReuseEnabled?: boolean
    stageStartEnabled?: boolean
    sizeSelectionEnabled?: boolean
    cellReuseEnabled?: boolean
    magBead?: boolean
    automationName?: string
    movieTime?: number
    automationParameters?: AutomationParameterObject[]
    templatePrepKit?: string
    bindingKit?: string
    sequencingKit?: string
    cellPac?: string
    controlKit?: string
    washKit?: string
    washKitParameters?: string
    primaryAutomationName?: string
    primaryConfigFileName?: string
    copyFiles?: string[]
    copyFilesPreset?: string
    readout?: string
    metricsVerbosity?: string
    subreadSetUuid?: string
    collectionMetadataUuid?: string
    useDynamicLoading?: boolean
    loadingTarget?: number
    maxLoadingTime?: number
    sampleGuid?: string
    enableCCS?: string
    ccsUuid?: string
    adapter?: string
    readSegmentation?: boolean
    scIsoSeq?: boolean
    cellType?: string
    immobilizationTime?: ImmobilizationTimeType
    isCLR?: boolean
    includeKinetics?: boolean
    include5mC?: boolean
    includeAllReads?: boolean
    detectHeteroduplex?: boolean
    emitSubreadsPercent?: number
    isIsoseq?: boolean
    extensionTime?: string
    consumables?: Consumable[]
    isBarcoded?: boolean
    barcodeUuid?: string
    barcodeName?: string
    sameBarcodesBothEnds?: boolean
    minBarcodeScore?: number
    barcodeSampleMap?: any
    barcodeUuidMap?: any
    barcodeContents?: string
    demuxBarcodes?: any
    assayPlateIdMap?: any
    assayPlateWellMap?: any
    sarsBarcodeFile?: any
    sarsBarcodeFilePath?: any
    barcodeSetNames?: string[]
    barcodeCSV?: any
    barcodeCSVName?: string
    isValidBarcodeDesign?: boolean
    multiJobId?: number
    multiJobJobs?: DeferredJob[]
    AutoAnalysisEnabled?: boolean
    AutoAnalysisName?: string // TODO: "AutoAnalysisName" or "analysisName" ???
    hasAutoAnalyses?: boolean
    consensusMode?: string
    fullResolutionBaseQual?: boolean
    subreadToHiFiPileup?: boolean
    barcodedSamples?: BarcodedSample[]

    // These extra samples are for cell reuse collections beyond the first collection.
    // Only the first SampleObject for a cell use should have this field instantiated.
    cellReuseSamples?: SampleObject[]

    // These properties are used to build a RunQcSampleObject from a SampleObject.
    startedAt?: string
    completedAt?: string
    status?: CollectionStateValue
    context?: string
    movieMinutes?: number
    terminationInfo?: string
    collectionPathUri?: string
    collectionNumber?: number
    actualLoadingTime?: number // seconds

    xml?: XMLElement
}
export interface AutomationParameterObject {
    name?: string
    type?: string
    value?: string
}
export type ImmobilizationTimeType = "default" | number

export class Consumable {
    constructor(
        public Barcode: string,
        public ExpirationDate: string,
        public LotNumber: string,
        public Name: string,
        public Well: string,
        public ActiveHours: number,
        public ActiveHoursLimit: number
    ) { }
}

export class AutomationParameterModel extends Model implements AutomationParameterObject {
    name = ""
    type = ""
    value = ""

    constructor(object?: AutomationParameterObject) {
        super()
        this.initialize(object)
    }
}


export class SampleModel extends Model implements SampleObject {
    sampleName = ""
    biosampleName = ""
    collectionsPerSMRTCell = 1
    wellName = "A01"
    plateNumber = 1
    application = ""
    libraryType = ""
    cellName = ""
    sampleDescription = ""
    insertSize = null
    projectId = GENERAL_PROJECT_ID
    loadingConcentration = 0
    stageStartEnabled = false
    sizeSelectionEnabled = false
    magBead = false
    isCLR = true
    isIsoseq = false
    includeKinetics = false
    include5mC = false
    includeAllReads = false
    detectHeteroduplex = false
    emitSubreadsPercent = 0
    automationName = ""
    adapter = "mas16"
    readSegmentation = false
    scIsoSeq = false
    cellType = "human"
    automationParameters: AutomationParameterModel[] = []
    primaryAutomationName = ""
    primaryConfigFileName = ""
    copyFiles: string[] = ["Fasta", "Bam"]
    copyFilesPreset = "Production"
    readout = "Bases_Without_QVs"
    metricsVerbosity = "Minimal"
    useDynamicLoading = false
    loadingTarget = appConfig.defaultLoadingTarget
    maxLoadingTime = 2
    sampleGuid = ""
    enableCCS = "None"
    ccsUuid = ""
    templatePrepKit = ""
    bindingKit = ""
    sequencingKit = ""
    cellPac = ""
    controlKit = ""
    washKit = ""
    consumables: Consumable[] = []
    isBarcoded = false
    barcodeUuid: string | undefined = ""
    barcodeName: string | undefined = ""
    sameBarcodesBothEnds: boolean | undefined = true
    minBarcodeScore = 80
    barcodeSampleMap = {}
    barcodeUuidMap = {}
    barcodeContents = ""
    demuxBarcodes = "None"
    assayPlateIdMap = {}
    assayPlateWellMap = {}
    sarsBarcodeFile = null
    sarsBarcodeFilePath = ""
    barcodeSetNames: string[]
    barcodeCSV = []
    barcodeCSVName = ""
    isValidBarcodeDesign = true
    multiJobId = 0
    multiJobJobs = []
    AutoAnalysisEnabled= false // Unrelated to auto-analysis options in RD!
    AutoAnalysisName = "" // Unrelated to auto-analysis options in RD!
    hasAutoAnalyses = false // Unrelated to auto-analysis options in RD!
    analysisName = ""
    pipelineId = ""
    entryPoints = []
    taskOptions = []
    presetId = ""
    subreadSetUuid = ""
    collectionMetadataUuid = ""
    cellReuseSamples: SampleModel[] = []
    // These properties are used to build a RunQcSampleObject from a SampleObject.
    startedAt: string
    completedAt: string
    status: CollectionStateValue
    context: string
    movieMinutes: number // I know this is redundant with movieTime. Keeping it separate to make future refactoring easier.
    terminationInfo: string
    collectionPathUri: string
    collectionNumber: number
    consensusMode = "molecule"
    fullResolutionBaseQual = false
    subreadToHiFiPileup = false
    barcodedSamples = []

    xml: XMLElement

    set movieTime(value: number) {
        const time = value * 60
        if (!this.automationParameters) {
            return
        }
        if (!this.movieLengthModel) {
            this.automationParameters.push(
                this.movieLengthModel = new AutomationParameterModel({
                    name: "MovieLength",
                    type: "Double",
                    value: time.toString()
                })
            )
        } else {
            this.movieLengthModel.value = time.toString()
        }
    }

    get movieTime(): number {
        if (!this.movieLengthModel) {
            return -1
        }
        let time = parseInt(this.movieLengthModel.value, 10)
        return time / 60
    }

    set immobilizationTime(value: ImmobilizationTimeType) {
        if (!this.automationParameters) {
            return
        }
        if (value === "default") {
            if (this.immobilizationTimeModel) {
                const index = this.automationParameters.indexOf(this.immobilizationTimeModel)
                this.automationParameters.splice(index, 1)
                this.immobilizationTimeModel = null
            }
        } else {
            value *= 60
            if (!this.immobilizationTimeModel) {
                this.automationParameters.push(
                    this.immobilizationTimeModel = new AutomationParameterModel({
                        name: "ImmobilizationTime",
                        type: "Double",
                        value: value.toString()
                    })
                )
            } else {
                this.immobilizationTimeModel.value = value.toString()
            }
        }
    }

    get immobilizationTime(): ImmobilizationTimeType {
        if (!this.immobilizationTimeModel) {
            return "default"
        }
        let value = parseInt(this.immobilizationTimeModel.value, 10)
        value = value / 60
        return value
    }

    set sampleReuseEnabled(value: boolean) {
        if (!this.automationParameters) {
            return
        }
        if (!this.sampleReuseEnabledModel) {
            this.automationParameters.push(
                this.sampleReuseEnabledModel = new AutomationParameterModel({
                    name: "ReuseSample",
                    type: "String",
                    value: value ? "True" : "False"
                })
            )
        } else {
            this.sampleReuseEnabledModel.value = value ? "True" : "False"
        }
    }

    get sampleReuseEnabled(): boolean {
        if (!this.sampleReuseEnabledModel) {
            return false
        }
        return this.sampleReuseEnabledModel.value === "True"
    }

    set cellReuseEnabled(value: boolean) {
        if (!this.automationParameters) {
            return
        }
        if (!this.cellReuseEnabledModel) {
            this.automationParameters.push(
                this.cellReuseEnabledModel = new AutomationParameterModel({
                    name: "ReuseCell",
                    type: "Boolean",
                    value: value ? "True" : "False"
                })
            )
        } else {
            this.cellReuseEnabledModel.value = value ? "True" : "False"
        }
    }

    get cellReuseEnabled(): boolean {
        if (!this.cellReuseEnabledModel) {
            return false
        }
        return this.cellReuseEnabledModel.value === "True"
    }

    set extensionTime(value: string) {
        if (value !== "None") {
            let valueNum = Number(value) * 60
            value = Math.round(valueNum).toString()
        }
        if (!this.automationParameters) {
            return
        }

        if (!this.extensionTimeModel) {
            this.automationParameters.push(
                this.extensionTimeModel = new AutomationParameterModel({
                    name: "ExtensionTime",
                    type: "Double",
                    value: (value !== "None") ? value : "0"
                })
            )
        } else {
            this.extensionTimeModel.value = (value !== "None") ? value : "0"
        }

        if (!this.extendFirstModel) {
            this.automationParameters.push(
                this.extendFirstModel = new AutomationParameterModel({
                    name: "ExtendFirst",
                    type: "Boolean",
                    value: (value !== "None" && value !== "0") ? "True" : "False"
                })
            )
        } else {
            this.extendFirstModel.value = (value !== "None" && value !== "0") ? "True" : "False"
        }

    }

    get extensionTime(): string {
        if (!this.extensionTimeModel || !this.extendFirstModel) {
            return "0"
        }
        return this.extendFirstModel.value === "True" ?
            Number(parseFloat(this.extensionTimeModel.value) / Number(60)).toString() : "0"
    }

    calcPreextensionUsed(): boolean {
        return this.extensionTime !== "None" && parseFloat(this.extensionTime) > 0
    }

    private movieLengthModel: AutomationParameterModel | null
    private immobilizationTimeModel: AutomationParameterModel | null
    private sampleReuseEnabledModel: AutomationParameterModel | null
    private cellReuseEnabledModel: AutomationParameterModel | null
    private extensionTimeModel: AutomationParameterModel | null
    private extendFirstModel: AutomationParameterModel | null

    // The extra set of conversions beyond automationParameters is needed
    // because a UI form model may be passed in to the constructor.
    // Ideally, the overall architecture could be simplified
    // so that the data model is more cleanly separated from the UI form.
    constructor(object?: SampleObject) {
        super()
        this.initialize(object)

        let movieTime = 10
        let immobilizationTime: ImmobilizationTimeType = "default"
        let sampleReuseEnabled = false
        let cellReuseEnabled = false
        let extensionTime = "0"
        if (object) {
            if (object.copyFiles) {
                this.copyFiles = object.copyFiles.slice(0)
            }
            if (object.automationParameters) {
                this.automationParameters = object.automationParameters.map(parameter => {
                    const model = new AutomationParameterModel(parameter)
                    if (model.name === "MovieLength") {
                        this.movieLengthModel = model
                    } else if (model.name === "ImmobilizationTime") {
                        this.immobilizationTimeModel = model
                    } else if (model.name === "ReuseSample") {
                        this.sampleReuseEnabledModel = model
                    } else if (model.name === "ReuseCell") {
                        this.cellReuseEnabledModel = model
                    } else if (model.name === "ExtensionTime") {
                        this.extensionTimeModel = model
                    } else if (model.name === "ExtendFirst") {
                        this.extendFirstModel = model
                    }

                    return model
                })
            }
            if (object.movieTime) {
                movieTime = object.movieTime
            }
            if (object.immobilizationTime) {
                immobilizationTime = object.immobilizationTime
            }
            if (object.sampleReuseEnabled) {
                sampleReuseEnabled = object.sampleReuseEnabled
            }
            if (object.cellReuseEnabled) {
                cellReuseEnabled = object.cellReuseEnabled
            }
            if (object.extensionTime) {
                extensionTime = object.extensionTime
            }
        }
        if (!this.automationParameters) {
            this.automationParameters = []
        }
        if (!this.movieLengthModel) {
            this.movieTime = movieTime
        }
        if (immobilizationTime !== "default") {
            this.immobilizationTime = immobilizationTime
        }
        if (!this.sampleReuseEnabledModel) {
            this.sampleReuseEnabled = sampleReuseEnabled
        }
        if (!this.cellReuseEnabledModel) {
            this.cellReuseEnabled = cellReuseEnabled
        }
        if (!this.extensionTimeModel || !this.extendFirstModel) {
            this.extensionTime = extensionTime
        }
    }

    update(object: SampleObject) {
        let movieTime: number | undefined
        let immobilizationTime: ImmobilizationTimeType | undefined
        let sampleReuseEnabled: boolean | undefined
        let cellReuseEnabled: boolean | undefined
        let extensionTime: string | undefined
        Object.keys(object).forEach(key => {
            if (key === "automationParameters") {
                this.movieLengthModel = null
                this.immobilizationTimeModel = null
                this.sampleReuseEnabledModel = null
                this.extensionTimeModel = null
                this.extendFirstModel = null
                this.cellReuseEnabledModel = null
                this.automationParameters = (object.automationParameters || []).map(parameter => {
                    const model = new AutomationParameterModel(parameter)
                    if (model.name === "MovieLength") {
                        this.movieLengthModel = model
                    } else if (model.name === "ImmobilizationTime") {
                        this.immobilizationTimeModel = model
                    } else if (model.name === "ReuseSample") {
                        this.sampleReuseEnabledModel = model
                    } else if (model.name === "ExtensionTime") {
                        this.extensionTimeModel = model
                    } else if (model.name === "ExtendFirst") {
                        this.extendFirstModel = model
                    } else if (model.name === "ReuseCell") {
                        this.cellReuseEnabledModel = model
                    }
                    return model
                })
            } else if (key === "movieTime") {
                movieTime = object[key]
            } else if (key === "immobilizationTime") {
                immobilizationTime = object[key]
            } else if (key === "sampleReuseEnabled") {
                sampleReuseEnabled = object[key]
            } else if (key === "cellReuseEnabled") {
                cellReuseEnabled = object[key]
            } else if (key === "extensionTime") {
                extensionTime = object[key]
            } else if (key === "copyFiles") {
                this.copyFiles = object.copyFiles ? object.copyFiles.slice() : []
            } else {
                this[key] = object[key]
            }
        })
        if (typeof movieTime !== "undefined") {
            this.movieTime = movieTime
        }
        if (typeof immobilizationTime !== "undefined") {
            this.immobilizationTime = immobilizationTime
        }
        if (typeof sampleReuseEnabled !== "undefined") {
            this.sampleReuseEnabled = sampleReuseEnabled
        }
        if (typeof cellReuseEnabled !== "undefined") {
            this.cellReuseEnabled = cellReuseEnabled
        }
        if (typeof extensionTime !== "undefined") {
            this.extensionTime = extensionTime
        }
    }
}

export interface Defaults {
    templatePrepKit?: string
    bindingKit?: string
    sequencingKit?: string
    controlKit?: string
    usePreExtension?: string
    extensionTime?: number | string // can be "formula"
    movieTime?: number
    oplcRange?: number[]
    insertSize?: number
    sameBarcodesBothEnds?: string
    usePredictiveLoading?: string
    hasAnalysis?: string
    sampleIsBarcoded?: string
    pipelineId?: string
}

export type ApplicationSystems = Record<string, Defaults>

export interface Application {
    title: string
    systems: ApplicationSystems
}

export interface ApplicationCategory {
    category: string
    applications: string[];
}

export type Applications = Record<string, Application>

export interface ApplicationsResponse {
    applicationCategories: ApplicationCategory[]
    applications: Applications
}
